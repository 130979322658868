import { Component, OnInit, ViewChild, TemplateRef, Input } from '@angular/core';
import { formatDate } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';

import { DataService } from '../../data';

import { APIService } from 'src/api';
import * as ApiModels from 'src/api';

interface CounterInfo extends ApiModels.CounterInfo {
  lastValueDateDisplay?: string;
  verificationDateDisplay?: string;
  installationDateDisplay?: string;
  currentValue?: number;
  volume?: number;
  editing?: boolean;
}

@Component({
  selector: 'app-meters',
  templateUrl: './meters.component.html',
  styleUrls: ['./meters.component.scss']
})
export class MetersComponent implements OnInit {
  @Input() mode: 'view' | 'edit';
  @ViewChild('historyDialogTemplate', { static: true }) private historyDialogTemplate: TemplateRef<any>;

  displayedColumns: string[] = ['counterName', 'counterNumber', 'verificationDate', 'installationDate', 'lastValueDate', 'lastValue'];
  dataSource: MatTableDataSource<CounterInfo>;
  selectedId: number;
  kh: number;

  editingMode: 'view' | 'row' | 'table';

  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private dataService: DataService,
    private apiService: APIService
  ) {
    this.editingMode = 'view';
  }

  ngOnInit() {
    this.kh = this.dataService.kh;
    this.getDataSource();
  }

  onClickHistoryButton(selectedRowData: CounterInfo) {
    this.selectedId = selectedRowData.pU_ID;
    const dialogRef = this.dialog.open(this.historyDialogTemplate);

    dialogRef.afterClosed().subscribe(result => {
      // console.log(`Dialog result: ${result}`);
    });
  }

  onChangeCurrentValue(id: number, value: number) {
    this.dataSource.data.map(rowData => {
      if (rowData.pU_ID === id) {
        rowData.currentValue = value;
        rowData.volume = Math.round((value - rowData.lastValue) * 10000) / 10000;
      }

      return rowData;
    });
  }

  onClickEditButton(selectedRowData: CounterInfo) {
    selectedRowData.editing = true;
    this.editingMode = 'row';
    this.editingMode = this.getEditing();
  }

  onClickSaveButton(selectedRowData: CounterInfo) {
    if (!selectedRowData.currentValue) {
      this.dataSource.data = this.dataSource.data.map(rowData => {
        if (rowData.counterNumber === selectedRowData.counterNumber) {
          rowData.editing = false;
        }

        return rowData;
      });

      this.getDataSource();
      return;
    }

    const data: ApiModels.PUValuesEdit = {
      pU_ID: selectedRowData.pU_ID,
      value: selectedRowData.currentValue,
      valueid: selectedRowData.lastValueID
    };

    this.apiService.patchEditValue(data).toPromise().then(response => {
      this.snackBar.open(response, 'Успешно', {
        duration: 5000,
      });
      selectedRowData.editing = false;
      this.getDataSource();
    }).catch(responseError => {
      this.snackBar.open(responseError, 'Ошибка', {
        duration: 5000,
      });
    });
  }

  onClickSendButton() {
    const findIndex = this.dataSource.data.findIndex(rowData => {
      return !rowData.currentValue ||
              rowData.currentValue < 0 ||
              rowData.currentValue - rowData.lastValue < 0;
    });
    if (findIndex > -1) {
      return;
    }

    const data: ApiModels.PUValues[] = this.dataSource.data.map(rowData => {
      return {
        pU_ID: rowData.pU_ID,
        value: rowData.currentValue
      };
    });
    this.apiService.postValues(data).toPromise().then(response => {
      this.snackBar.open(response, 'Успешно', {
        duration: 5000,
      });
      this.getDataSource();

    }).catch(responseError => {
      this.snackBar.open(responseError, 'Ошибка', {
        duration: 5000,
      });
    });
  }

  private getDataSource() {
    this.apiService.getCounters(this.kh).toPromise().then(response => {
      this.dataSource = new MatTableDataSource(response);
      this.dataSource = this.normalizeDataSource(this.dataSource);
      this.editingMode = this.getEditing();
    });
  }

  private normalizeDataSource(dataSource: MatTableDataSource<CounterInfo>) {
    dataSource.data = dataSource.data.map(item => {
      item.lastValueDate = item.lastValueDate ? new Date(item.lastValueDate) : null;
      item.verificationDate = item.verificationDate ? new Date(item.verificationDate) : null;
      item.installationDate = item.installationDate ? new Date(item.installationDate) : null;

      item.lastValueDateDisplay = item.lastValueDate ? formatDate(item.lastValueDate, 'dd.MM.yyyy', 'ru') : '—';
      item.verificationDateDisplay = item.verificationDate ? formatDate(item.verificationDate, 'dd.MM.yyyy', 'ru') : '—';
      item.installationDateDisplay = item.installationDate ? formatDate(item.installationDate, 'dd.MM.yyyy', 'ru') : '—';

      const currentMonth = new Date().getMonth();
      const currentYear = new Date().getFullYear();
      const lastValueMonth = item.lastValueDate.getMonth();
      const lastValueYear = item.lastValueDate.getFullYear();
      item.editing = (currentYear === lastValueYear && currentMonth > lastValueMonth) || currentYear > lastValueYear;

      return item;
    });

    return dataSource;
  }

  private getEditing() {
    if (this.mode === 'view') {
      return 'view';
    }

    const findIndexEditingRow = this.dataSource.data.findIndex(rowData => rowData.editing);
    if (findIndexEditingRow > -1) {
      this.displayedColumns.push('currentValue');
      this.displayedColumns.push('volume');

      if (this.editingMode === 'row') {
        return 'row';
      }

      return 'table';
    }

    let index = this.displayedColumns.indexOf('currentValue');
    if (index !== -1) {
      this.displayedColumns.splice(index, 1);
    }
    index = this.displayedColumns.indexOf('volume');
    if (index !== -1) {
      this.displayedColumns.splice(index, 1);
    }

    return 'row';
  }
}
