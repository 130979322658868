import { Component, OnInit } from '@angular/core';

import { DataService } from '../../data';

import { APIService } from 'src/api';
import * as ApiModels from 'src/api';

interface FeatureInfoGroup {
  groupName: string;
  fields: ApiModels.FeatureInfo[];
}

@Component({
  selector: 'app-account-card',
  templateUrl: './account-card.component.html',
  styleUrls: ['./account-card.component.scss']
})
export class AccountCardComponent implements OnInit {
  groupedDataByGroup: FeatureInfoGroup[];
  kh: number;

  constructor(
    private dataService: DataService,
    private apiService: APIService
  ) { }

  ngOnInit() {
    this.kh = this.dataService.kh;
    this.apiService.getFeatures(this.kh).toPromise().then(response => {
      this.groupedDataByGroup = this.getGroupedDataByGroup(response);
    });
  }

  getGroupedDataByGroup(fields: ApiModels.FeatureInfo[]): FeatureInfoGroup[] {
    const groupedData = fields.reduce((groups, field) => {
      const groupName = field.groupName;
      if (!groups[groupName]) {
        groups[groupName] = [];
      }
      groups[groupName].push(field);
      return groups;
    }, {});

    const groupedDataByGroup: FeatureInfoGroup[] = Object.keys(groupedData).map((groupName) => {
      return {
        groupName,
        fields: groupedData[groupName]
      };
    });

    return groupedDataByGroup;
  }

}
