import { Injectable, EventEmitter } from '@angular/core';
import jwt_decode from 'jwt-decode';

import { AuthService } from '../auth';

import { APIService } from 'src/api';
import * as ApiModels from 'src/api';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  kh: number;
  userInfo: ApiModels.UserInfo;
  onGetData: EventEmitter<ApiModels.UserInfo>;

  constructor(
    private authService: AuthService,
    private apiService: APIService
  ) {
    this.onGetData = new EventEmitter();
  }

  getData() {
    const token = this.authService.getToken();

    if (!token) {
      return;
    }

    const jsonToken: any = jwt_decode(token);
    this.kh = jsonToken.nameid;

    this.apiService.getUserInfo(this.kh).toPromise().then(response => {
      this.userInfo = response;
      this.onGetData.emit(response);
    });
  }
}
