import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';

import { DataService } from '../data';

import { APIService } from 'src/api';
import * as ApiModels from 'src/api';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  displayedColumnsEmails: string[] = ['iWish', 'value', 'action'];
  dataSourceEmails: MatTableDataSource<ApiModels.Contact>;
  displayedColumnsPhones: string[] = ['iWish', 'value', 'action'];
  dataSourcePhones: MatTableDataSource<ApiModels.Contact>;
  kh: number;
  deletedData: ApiModels.Contacts;

  password: string;
  passwordHide: boolean;
  newPassword: string;
  newPasswordHide: boolean;
  confirmPassword: string;
  confirmPasswordHide: boolean;

  formBuilder: FormBuilder;
  formFields: FormGroup;
  changePasswordForm: FormGroup;

  constructor(
    private snackBar: MatSnackBar,
    private dataService: DataService,
    private apiService: APIService
  ) {
    this.passwordHide = true;
    this.newPasswordHide = true;
    this.confirmPasswordHide = true;
  }

  ngOnInit() {
    this.kh = this.dataService.kh;
    this.getContactsData(this.kh);

    this.formBuilder = new FormBuilder();
    this.changePasswordForm = this.formBuilder.group({
      password: new FormControl(null, [Validators.required]),
      newPassword:   new FormControl(null, [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')
      ]),
      confirmPassword: new FormControl(null, [Validators.required])
    }, {
      validator: this.checkPasswords
    });
  }

  onClickChangePasswordButton() {
    if (this.changePasswordForm.invalid) {
      return;
    }

    this.apiService.changePassword({
      oldPassword: this.changePasswordForm.get('password').value,
      newPassword: this.changePasswordForm.get('newPassword').value,
      newPasswordCompare: this.changePasswordForm.get('confirmPassword').value
    }).toPromise()
      .then(response => {
        this.changePasswordForm.reset();
        this.snackBar.open('Пароль успешно изменён', 'Ок', {
          duration: 5000,
        });
      })
      .catch(error => {
        this.snackBar.open(error, 'Ошибка', {
          duration: 5000,
        });
      });
  }

  checkedEmailRow(row: ApiModels.Contact) {
    if (row) {
      this.dataSourceEmails.data = this.dataSourceEmails.data.map(item => {
        if (item.id === row.id && item.value === row.value) {
          item.iWish = !item.iWish;
        }
        return item;
      });
    }
  }

  onClickDeleteEmailButton(row: ApiModels.Contact) {
    if (row) {
      row.value = null;
      this.deletedData.emails.push(row);
      this.dataSourceEmails.data = this.dataSourceEmails.data.filter(item => item.id !== row.id);
    }
  }

  onClickAddEmailButton() {
    this.dataSourceEmails.data.push({ id: 0, iWish: false, value: '', kh: this.kh });
    this.dataSourceEmails.data = this.dataSourceEmails.data; // TODO hack
  }

  checkedPhoneRow(row: ApiModels.Contact) {
    if (row) {
      this.dataSourcePhones.data = this.dataSourcePhones.data.map(item => {
        if (item.id === row.id && item.value === row.value) {
          item.iWish = !item.iWish;
        }
        return item;
      });
    }
  }

  phoneNumberValidated(e: any) {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');
  }

  onClickDeletePhoneButton(row: ApiModels.Contact) {
    if (row) {
      row.value = null;
      this.deletedData.phones.push(row);
      this.dataSourcePhones.data = this.dataSourcePhones.data.filter(item => item.id !== row.id);
    }
  }

  onClickAddPhoneButton() {
    this.dataSourcePhones.data.push({ id: 0, iWish: false, value: '', kh: this.kh });
    this.dataSourcePhones.data = this.dataSourcePhones.data; // TODO hack
  }

  onClickSaveContactsButton() {
    this.dataSourceEmails.data = this.dataSourceEmails.data.filter(row => (row.id === 0 && row.value !== '') || row.id);
    this.dataSourcePhones.data = this.dataSourcePhones.data.filter(row => (row.id === 0 && row.value !== '') || row.id);

    const contactsData: ApiModels.Contacts = {
      emails: [],
      phones: []
    };

    contactsData.emails.push(...this.deletedData.emails, ...this.dataSourceEmails.data);
    contactsData.phones.push(...this.deletedData.phones, ...this.dataSourcePhones.data);

    this.apiService.postContacts(contactsData).toPromise().then(response => {
      this.getContactsData(this.kh);
    });
  }

  private getContactsData(kh: number) {
    this.apiService.getContacts(kh).toPromise().then(response => {
      this.dataSourceEmails = new MatTableDataSource(response.emails);
      this.dataSourceEmails.data.push({ id: 0, iWish: false, value: '', kh: this.kh });
      this.dataSourcePhones = new MatTableDataSource(response.phones);
      this.dataSourcePhones.data.push({ id: 0, iWish: false, value: '', kh: this.kh });

      this.deletedData = {
        emails: [],
        phones: []
      };
    });
  }

  private checkPasswords(group: FormGroup) {
    const newPassControl = group.controls.newPassword;
    const confirmPassControl = group.controls.confirmPassword;
    return newPassControl.value === confirmPassControl.value ?
      confirmPassControl.setErrors(null) : confirmPassControl.setErrors({ notEquivalent: true });
  }
}
