import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { DataService } from '../../data';

import * as ApiModels from 'src/api';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  userInfo: ApiModels.UserInfo;
  kh: number;

  constructor(
    private router: Router,
    private dataService: DataService
  ) {
    this.subscription = this.dataService.onGetData.subscribe(response => {
      this.userInfo = response;
    });
  }

  ngOnInit() {
    this.dataService.getData();
  }

  onClickHomeButton() {
    this.router.navigate(['/']);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
