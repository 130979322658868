import { Component, OnInit } from "@angular/core";
import { formatDate } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { MatTableDataSource } from "@angular/material/table";

import { DataService } from "../../data";

import { APIService } from "src/api";
import * as ApiModels from "src/api";

interface ChargesInfo extends ApiModels.ChargesInfo {
  currentValue?: number;
  volume?: number;
}

interface Period {
  value: Date;
  displayValue: string;
}

@Component({
  selector: "app-accruals",
  templateUrl: "./accruals.component.html",
  styleUrls: ["./accruals.component.scss"],
})
export class AccrualsComponent implements OnInit {
  displayedColumns: string[] = [
    "serviceName",
    "unit",
    "tariff",
    "volume",
    "rate",
    "benefitAmount",
    "allocationAmount",
    "total",
  ];
  dataSource: MatTableDataSource<ChargesInfo>;

  periods: Period[];
  selectedPeriod: Date;
  kh: number;

  constructor(
    private http: HttpClient,
    private dataService: DataService,
    private apiService: APIService
  ) {
    // TODO refactoring - переписать на контрол с календарём
    this.periods = [];
    const yearStart = 2019;
    const yearEnd = new Date().getFullYear();
    for (let year = yearStart; year <= yearEnd; year++) {
      for (let month = 0; month < 12; month++) {
        if (new Date(year, month, 2) > new Date()) {
          return;
        }

        this.periods.push({
          value: new Date(year, month, 2),
          displayValue: formatDate(new Date(year, month, 2), "LLLL yyyy", "ru"),
        });
      }
    }
  }

  ngOnInit() {
    this.kh = this.dataService.kh;
    this.selectedPeriod = this.periods.find((period) => {
      return (
        formatDate(period.value, "MM.yyyy", "ru") ===
        formatDate(new Date(), "MM.yyyy", "ru")
      );
    }).value;

    this.apiService
      .getCharges(this.kh, this.selectedPeriod)
      .toPromise()
      .then((response) => {
        this.dataSource = new MatTableDataSource(response);
      });
  }

  periodChange(event: any) {
    this.apiService
      .getCharges(this.kh, this.selectedPeriod)
      .toPromise()
      .then((response) => {
        this.dataSource = new MatTableDataSource(response);
      });
  }

  getAccrualTotalCost(fieldName: string) {
    if (!this.dataSource) {
      return;
    }

    return this.dataSource.data
      .map((rowData) => rowData[fieldName])
      .reduce((acc, value) => acc + value, 0)
      .toFixed(2);
  }

  onClickDownloadButton() {
    if (!this.kh || !this.selectedPeriod) {
      return;
    }

    this.http
      .get(
        `/api/invoice?kh=${this.kh}&period=${encodeURIComponent(
          this.selectedPeriod ? "" + this.selectedPeriod.toJSON() : ""
        )}`,
        { responseType: "blob" }
      )
      .subscribe((response: any) => {
        const blob = new Blob([response]);
        const url = window.URL.createObjectURL(blob);
        const a = window.document.createElement("a");
        window.document.body.appendChild(a);
        a.href = url;
        a.download = `Квитанция за ${formatDate(
          this.selectedPeriod,
          "LLLL yyyy",
          "ru"
        )}.pdf`;
        a.click();
        window.URL.revokeObjectURL(url);
      });
  }
}
