import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppLayoutComponent } from './app-layout';
import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { PageNotFoundComponent } from './page-not-found';
import { PaymentComponent } from './payment';
import { ProfileComponent } from './profile';
import { TransmissionReadingsComponent } from './transmission-readings';
import { AuthGuard } from './auth/guards/auth.guard';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    data: { title: 'Вход в Личный кабинет' },
  },
  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: HomeComponent,
        data: { title: 'Личный кабинет - Главная' },
      },
      {
        path: 'payment',
        component: PaymentComponent,
        data: { title: 'Личный кабинет - Оплата' },
      },
      {
        path: 'profile',
        component: ProfileComponent,
        data: { title: 'Личный кабинет - Профиль' },
      },
      {
        path: 'transmission',
        component: TransmissionReadingsComponent,
        data: { title: 'Личный кабинет - Передача показаний' },
      },
      {
        path: '**',
        component: PageNotFoundComponent,
        data: { title: 'Личный кабинет - Страница не найдена' },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
