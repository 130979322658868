import { Component, Input, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';

import { APIService } from 'src/api';
import * as ApiModels from 'src/api';

interface CounterValue extends ApiModels.CounterValue {
  valueDateDisplay?: string;
}

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {
  @Input() meterId: number;

  displayedColumns: string[] = ['value', 'valueDate'];
  dataSource: MatTableDataSource<CounterValue>;

  constructor(
    private apiService: APIService
  ) { }

  ngOnInit() {
    this.apiService.getCounterValues(this.meterId).toPromise().then(response => {
      this.dataSource = new MatTableDataSource(response);
      this.dataSource = this.normalizeDataSource(this.dataSource);
    });
  }

  private normalizeDataSource(dataSource: MatTableDataSource<CounterValue>) {
    dataSource.data = dataSource.data.map(item => {
      item.valueDateDisplay = item.valueDate ? formatDate(item.valueDate, 'dd.MM.yyyy', 'ru') : '—';
      return item;
    });
    return dataSource;
  }
}
