import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DataService } from '../../data';

import { APIService } from 'src/api';
import * as ApiModels from 'src/api';

interface DocumentInfoGroup {
  groupName: string;
  fields: ApiModels.DocumentInfo[];
}

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {
  groupedDataByGroup: DocumentInfoGroup[];
  kh: number;

  constructor(
    private http: HttpClient,
    private dataService: DataService,
    private apiService: APIService
  ) { }

  ngOnInit() {
    this.kh = this.dataService.kh;
    this.apiService.getDocuments(this.kh).toPromise().then(response => {
      this.groupedDataByGroup = this.getGroupedDataByGroup(response);
    });
  }

  onClickDownloadButton(id: number, fileName: string) {
    this.http.get(
      `/api/docs/GetDocumentById?kh=${this.kh}&id=${id}`,
      { responseType: 'blob' }
    ).subscribe((response: any) => {
      const blob = new Blob([response]);
      const url = window.URL.createObjectURL(blob);
      const a = window.document.createElement('a');
      window.document.body.appendChild(a);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    });
  }

  private getGroupedDataByGroup(fields: ApiModels.DocumentInfo[]): DocumentInfoGroup[] {
    const groupedData = fields.reduce((groups, field) => {
      const groupName = field.groupName;
      if (!groups[groupName]) {
        groups[groupName] = [];
      }
      groups[groupName].push(field);
      return groups;
    }, {});

    const groupedDataByGroup: DocumentInfoGroup[] = Object.keys(groupedData).map((groupName) => {
      return {
        groupName,
        fields: groupedData[groupName]
      };
    });

    return groupedDataByGroup;
  }
}
