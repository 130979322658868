import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';

import { DataService } from '../../data';

import { APIService } from 'src/api';
import * as ApiModels from 'src/api';

interface PaymentInfo extends ApiModels.PaymentInfo {
  paymentDateDisplay?: string;
  periodDisplay?: string;
}

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  displayedColumns: string[] = ['paymentDate', 'period', 'paymentAmount'];
  dataSource: MatTableDataSource<PaymentInfo>;
  kh: number;

  constructor(
    private dataService: DataService,
    private apiService: APIService
  ) { }

  ngOnInit() {
    this.kh = this.dataService.kh;
    this.apiService.getPayments(this.kh).toPromise().then(response => {
      this.dataSource = new MatTableDataSource(response);
      this.dataSource = this.normalizeDataSource(this.dataSource);
    });
  }

  private normalizeDataSource(dataSource: MatTableDataSource<PaymentInfo>) {
    dataSource.data = dataSource.data.map(item => {
      item.paymentDateDisplay = formatDate(item.paymentDate, 'dd.MM.yyyy', 'ru');
      item.periodDisplay = formatDate(item.period, 'LLLL yyyy', 'ru');
      return item;
    });
    return dataSource;
  }

}
