import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppLayoutModule } from './app-layout';
import { AppRoutingModule } from './app-routing.module';
import { HomeModule } from './home';
import { LoginModule } from './login';
import { PageNotFoundModule } from './page-not-found';
import { PaymentModule } from './payment';
import { ProfileModule } from './profile';
import { SharedModule } from './shared/shared.module';
import { TransmissionReadingsModule } from './transmission-readings';

import { AuthService, TokenInterceptor } from './auth';

import { APIService, TokenService } from '../api';

registerLocaleData(localeRu, 'ru');

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppLayoutModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    HomeModule,
    HttpClientModule,
    LoginModule,
    PageNotFoundModule,
    PaymentModule,
    ProfileModule,
    SharedModule,
    TransmissionReadingsModule
  ],
  providers: [
    APIService,
    AuthService,
    TokenService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'ru' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
