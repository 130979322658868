import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss']
})
export class AppLayoutComponent implements OnInit {
  @ViewChild('footerDiv', { static: true }) private footerDivElement: ElementRef;

  currentUrl: string;

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    this.currentUrl = this.router.url;
    this.setFooterClass(this.currentUrl);

    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.currentUrl = e.url;
        this.setFooterClass(this.currentUrl);
      }
    });
  }

  setFooterClass(url: string) {
    let newClass: string;

    switch (url) {
      case '/payment':
        newClass = 'layout__footer_red';
        break;

      case '/transmission':
        newClass = 'layout__footer_blue';
        break;

      case '/profile':
        newClass = 'layout__footer_green';
        break;

      default:
        newClass = 'layout__footer_gray';
        break;
    }

    this.footerDivElement.nativeElement.classList = [];
    this.footerDivElement.nativeElement.classList.add('layout__footer');
    this.footerDivElement.nativeElement.classList.add(newClass);
  }

}
