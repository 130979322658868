import { Component, OnInit, OnDestroy } from '@angular/core';
import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { DataService } from '../data';

import * as ApiModels from 'src/api';

interface UserInfo extends ApiModels.UserInfo {
  balanceDisplay?: string;
  nextDayCount?: number;
  lastDate?: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  userInfo: UserInfo;

  constructor(
    private http: HttpClient,
    private dataService: DataService
  ) {
    this.subscription = this.dataService.onGetData.subscribe(response => {
      this.userInfo = response;
      this.userInfo = this.normalizeUserInfo(this.userInfo);
    });
  }

  ngOnInit() {
    if (!this.userInfo) {
      this.userInfo = this.dataService.userInfo;
      this.dataService.getData();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onClickDownloadLastAccrual() {
    const kh = this.dataService.kh;
    if (!kh) {
      return;
    }

    const currentDate = new Date();

    this.http.get(
      `/api/invoice?kh=${kh}&period=${encodeURIComponent(currentDate ? '' + currentDate.toJSON() : '')}`,
      { responseType: 'blob' }
    ).subscribe((response: any) => {
      const blob = new Blob([response]);
      const url = window.URL.createObjectURL(blob);
      const a = window.document.createElement('a');
      window.document.body.appendChild(a);
      a.href = url;
      a.download = `Квитанция за ${formatDate(currentDate, 'LLLL yyyy', 'ru')}.pdf`;
      a.click();
      window.URL.revokeObjectURL(url);
    });
  }

  private normalizeUserInfo(userInfo: UserInfo) {
    userInfo.balanceDisplay = ((userInfo.balance > 0) ? '+ ' : ((userInfo.balance < 0) ? '- ' : '')) + userInfo.balance;
    userInfo.lastDate = userInfo.lasT_VALUE_DATE ? formatDate(userInfo.lasT_VALUE_DATE, 'dd.MM.yyyy', 'ru') : '—';
    userInfo.nextDayCount = userInfo.begin_Counters;

    return userInfo;
  }
}
