import { Component, OnInit } from '@angular/core';

import { DataService } from '../data';
import { APIService } from '../../api';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  kh: number;
  sum: number;
  checked: boolean;
  urlPay: string;

  constructor(
    private dataService: DataService,
    private apiService: APIService
  ) {
    this.checked = false;
   }

  ngOnInit() {
    this.sum = this.dataService.userInfo && this.dataService.userInfo.balance < 0 ? this.dataService.userInfo.balance * -1 : undefined;
    this.kh = this.dataService.kh;
    this.apiService.getPaymentsURL(this.kh).toPromise().then(response => {
      this.urlPay = response;
    });
  }

  pay() {
    if (!this.sum || !this.checked) {
      return;
    }

    window.open(this.urlPay, '_blank').focus();
  }

}
