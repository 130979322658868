import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-transmission-readings',
  templateUrl: './transmission-readings.component.html',
  styleUrls: ['./transmission-readings.component.scss']
})
export class TransmissionReadingsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}
