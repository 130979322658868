import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';

import { TokenService } from 'src/api';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  token: string;
  refreshToken: string;

  cachedRequests: Array<HttpRequest<any>> = [];

  constructor(
    private tokenService: TokenService,
    private http: HttpClient,
    private router: Router
  ) { }

  setToken(token: string) {
    window.localStorage.setItem('token', token);
  }

  getToken() {
    return window.localStorage.getItem('token');
  }

  removeToken() {
    window.localStorage.removeItem('token');
    this.token = undefined;
  }

  setRefreshToken(refreshToken: string) {
    window.localStorage.setItem('refreshToken', refreshToken);
  }

  getRefreshToken() {
    return window.localStorage.getItem('refreshToken');
  }

  removeRefreshToken() {
    window.localStorage.removeItem('refreshToken');
    this.refreshToken = undefined;
  }

  isLoggedIn() {
    const token = this.getToken();
    return token !== null && token !== undefined && token !== '';
  }

  login(login: string, password: string) {
    return this.tokenService.getToken({
      login,
      password
    }).toPromise().then(response => {
      this.token = response.token;
      this.refreshToken = response.refreshToken;
      if (this.token) {
        this.setToken(this.token);
      }
      if (this.refreshToken) {
        this.setRefreshToken(this.refreshToken);
      }
    });
  }

  logout() {
    this.removeToken();
    this.removeRefreshToken();
    if (this.router && this.router.url.indexOf('returnUrl') === -1) {
      this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url }});
    } else {
      window.location.href = '/login'; // #TODO use 'location.href' because the 'router' does not always exist
    }
  }

  collectFailedRequest(request: any) {
    this.cachedRequests.push(request);
  }

  retryFailedRequests() {
    // retry the requests. this method can
    // be called after the token is refreshed
  }
}
